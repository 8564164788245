<template>
  <div>
    <validation-observer
      v-if="loading !== 'error'"
      ref="validateScheme"
    >
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <b-form
        v-else
        @submit.prevent
      >
        <b-row>
          <b-col
            cols="12"
            xl="8"
          >
            <XyzTransitionGroup
              appear
              class="item-group"
              xyz="fade stagger-2 left-3"
              mode="out-in"
            >
              <b-card key="1">
                <back-navigation
                  :title="editMode ? 'Edit Scheme' : copyMode ? 'Copy Scheme' : 'Tambah Scheme'"
                  :prevent-back="schemeData.color !== '' || schemeData.schemeName !== '' || schemeData.selectedItem.length > 0"
                  @preventBack="$bvModal.show('preventBack')"
                />
                <b-modal
                  id="preventBack"
                  title="Ingin keluar?"
                  centered
                >
                  Yakin ingin keluar? Progress pengisian form anda belum disimpan.
                  <template
                    #modal-footer
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      @click="$bvModal.hide('preventBack'); $router.go(-1)"
                    >
                      Ya, keluar
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="$bvModal.hide('preventBack')"
                    >
                      Tetap Disini
                    </b-button>
                  </template>
                </b-modal>
                <h6
                  v-if="copyMode"
                  class="text-muted mb-1 font-weight-normal"
                >
                  Membuat salinan scheme dari <router-link :to="{ name: 'scheme-detail', params: {id: id.toString()} }">
                    {{ prevScheme.name }}
                  </router-link>. Beri nama dan notes baru untuk scheme ini.
                </h6>
                <b-row>
                  <b-col
                    cols="12"
                    xl="6"
                  >
                    <b-form-group label="Nama Scheme">
                      <validation-provider
                        #default="{ errors }"
                        name="Nama Scheme"
                        rules="required"
                      >
                        <b-form-input
                          v-model="schemeData.schemeName"
                          placeholder="Nama Scheme"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="6"
                  >
                    <b-form-group label="Pilih Warna">
                      <v-select
                        v-model="schemeData.color"
                        label="eng_name"
                        :filter="fuseSearch"
                        :options="ralColors"
                        placeholder="Pilih Warna"
                      >
                        <template v-slot:option="item">
                          <div class="d-flex align-items-center">
                            <div
                              class="customswatch mr-50"
                              :style="`background-color: #${item.hex_code};`"
                            />
                            <div>
                              <h6 class="m-0">
                                {{ item.eng_name }}
                              </h6>
                              <small>{{ item.id_ral }}</small>
                            </div>
                            <h5 class="m-0 ml-auto">
                              {{ item.id_ral }}
                            </h5>
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group label="Notes">
                  <b-form-input
                    v-model="schemeData.notes"
                    placeholder="Beri keterangan tentang scheme ini..."
                  />
                </b-form-group>
              </b-card>
              <b-card
                key="2"
                no-body
                title="Item"
              >
                <b-card-body>
                  <item-auto-suggest
                    class="mb-25"
                    @onSelected="handleSelectedItem"
                  />
                  <small :class="showError && schemeData.selectedItem.length < 1 ? 'text-danger' : 'text-muted'">Pilih item terlebih dahulu, lalu isi detail di bawah ini</small>
                </b-card-body>
                <hr class="m-0">
                <b-card-body>
                  <h4>Main Item</h4>
                  <b-overlay
                    :show="loadingItem"
                  >
                    <vue-good-table
                      :columns="itemColumn"
                      :rows="schemeData.selectedItem"
                      :sort-options="{ enabled: false }"
                      :pagination-options="{
                        enabled: false,
                      }"
                      style-class="vgt-table mt-1"
                    >
                      <div slot="emptystate">
                        <small class="text-center d-block">Pilih item terlebih dahulu</small>
                      </div>
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                        <span v-if="props.column.field === 'name'">
                          <h6 class="m-0">{{ props.row.data.name }}</h6>
                          <small>{{ props.row.data.no }}</small>
                        </span>
                        <span v-else-if="props.column.field === 'color'">
                          <div
                            v-if="props.row.data.color"
                            class="d-flex"
                          >
                            <div
                              class="mr-50 mt-25"
                              :style="`background-color: #${props.row.data.color.hex_code}; width: 36px; height: 36px; border-radius: 2px`"
                            />
                            <div>
                              <h6 class="m-0">{{ props.row.data.color.eng_name }}</h6>
                              <small>{{ props.row.data.color.id_ral }}</small>
                            </div>
                          </div>
                        </span>
                        <span v-else-if="props.column.field === 'vs_volume_solid'">
                          <h6 class="m-0">{{ props.row.data.vs_volume_solid }}</h6>
                        </span>
                        <span v-else-if="props.column.field === 'coat'">
                          <validation-provider
                            #default="{ errors }"
                            :name="`Coat ${props.row.originalIndex}`"
                            rules="integer|min_value:0|max_value:100|positive"
                          >
                            <b-input-group
                              append="%"
                              class="input-group-merge"
                            >
                              <b-form-input
                                v-model="schemeData.selectedItem[props.row.originalIndex].coat"
                                placeholder="100"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </span>
                        <span v-else-if="props.column.field === 'dft'">
                          <validation-provider
                            #default="{ errors }"
                            :name="`DFT ${props.row.originalIndex}`"
                            rules="integer|min_value:0|max_value:1000|positive"
                          >
                            <b-input-group
                              append="μm"
                              class="input-group-merge"
                            >
                              <b-form-input
                                v-model="schemeData.selectedItem[props.row.originalIndex].dft"
                                placeholder="100"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </span>
                        <span v-else-if="props.column.field === 'loss'">
                          <validation-provider
                            #default="{ errors }"
                            :name="`DFT ${props.row.originalIndex}`"
                            rules="integer|min_value:0|max_value:100|positive"
                          >
                            <b-input-group
                              append="%"
                              class="input-group-merge"
                            >
                              <b-form-input
                                v-model="schemeData.selectedItem[props.row.originalIndex].loss"
                                placeholder="100"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </span>
                        <span v-else-if="props.column.field === 'balance'">
                          <h6 class="m-0">{{ props.row.data.balance == null ? 0 : props.row.data.balance }}</h6>
                        </span>
                        <span v-else-if="props.column.field === 'price'">
                          <h6
                            v-if="props.row.data.detailSellingPrice.length"
                            class="m-0"
                          >{{ formatCurrency(props.row.data.detailSellingPrice[0].price) }}</h6>
                        </span>
                        <span v-else-if="props.column.field === 'action'">
                          <div class="d-flex align-items-center justify-content-end">
                            <b-img
                              v-if="props.row.data.data_sheet"
                              class="cursor-pointer mr-1"
                              :src="require('@/assets/images/icons/pdf.png')"
                              height="auto"
                              width="20"
                              title="Data Sheet"
                              @click="$refs.pdf.show(props.row.data.data_sheet)"
                            />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-danger"
                              @click="deleteItem(props.row)"
                            >Hapus</b-button>
                          </div>
                        </span>
                      </template>
                    </vue-good-table>
                  </b-overlay>
                </b-card-body>
              </b-card>
            </XyzTransitionGroup>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <sticky-panel>
              <XyzTransition
                appear
                xyz="fade right-3 delay-2"
              >
                <b-card v-if="schemeData.schemeName && schemeData.selectedItem.length">
                  <h5 class="text-primary">
                    {{ schemeData.schemeName }}
                  </h5>
                  <div
                    v-if="schemeData.color"
                    class="d-flex align-items-center"
                  >
                    <div
                      class="mr-50 mt-25"
                      :style="`background-color: #${schemeData.color.hex_code}; width: 36px; height: 36px; border-radius: 2px`"
                    />
                    <div>
                      <small class="text-muted">{{ schemeData.color.id_ral }}</small>
                      <h5 class="m-0">
                        {{ schemeData.color.eng_name }}
                      </h5>
                    </div>
                  </div>
                  <hr>
                  <small
                    v-if="schemeData.selectedItem.length"
                    class="text-muted"
                  >Item</small>
                  <div
                    v-for="(item, index) in schemeData.selectedItem"
                    :key="`${item.no}${index}`"
                    class="mb-1"
                  >
                    <h6 class="m-0">
                      {{ item.data.name }}
                    </h6>
                    <small>{{ item.data.no }}</small>
                  </div>
                  <hr>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    :disabled="loadingAdd"
                    variant="primary"
                    @click="editMode ? updateScheme() : submitForm()"
                  >
                    <span v-if="!loadingAdd">{{ editMode ? 'Update Scheme' : 'Submit' }}</span>
                    <b-spinner
                      v-else
                      small
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="outline-secondary"
                    @click="resetForm()"
                  >
                    Reset
                  </b-button>
                </b-card>
              </XyzTransition>
            </sticky-panel>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <error-endpoint
      v-else
      :key="error"
      :error="error"
    />
    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>

import {
  BCard, BCardBody, BForm, BRow, BCol, BFormGroup, BInputGroup, BButton, VBToggle,
  BSpinner, BFormInput, BOverlay, BImg, BModal,
} from 'bootstrap-vue'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemAutoSuggest from '@/components/input/ItemAutoSuggest.vue'
import StickyPanel from '@/components/misc/StickyPanel.vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import {
  required,
} from '@validations'
import { formatCurrency } from '@core/utils/filter'
import Fuse from 'fuse.js'
import PdfPreview from '@/components/misc/PdfPreview.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BackNavigation,
    ItemAutoSuggest,
    vSelect,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    StickyPanel,
    BFormGroup,
    BInputGroup,
    BButton,
    BSpinner,
    BFormInput,
    BOverlay,
    BImg,
    BModal,
    ErrorEndpoint,
    PdfPreview,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showError: false,
      formatCurrency,
      required,
      loading: false,
      loadingItem: false,
      loadingAdd: false,
      schemeData: {
        schemeName: '',
        notes: '',
        color: '',
        selectedItem: [],
      },
      prevScheme: '',
      error: '',
      itemColumn: [
        {
          label: 'Item',
          field: 'name',
          width: '240px',
        },
        {
          label: 'Warna',
          field: 'color',
          width: '180px',
        },
        {
          label: 'VS',
          field: 'vs_volume_solid',
        },
        {
          label: 'Coat',
          field: 'coat',
          width: '110px',
        },
        {
          label: 'DFT',
          field: 'dft',
          width: '120px',
        },
        {
          label: 'Loss',
          field: 'loss',
          width: '110px',
        },
        {
          label: 'Balance',
          field: 'balance',
        },
        {
          label: 'Harga',
          field: 'price',
        },
        {
          label: '',
          field: 'action',
          tdClass: 'text-right',
          width: '140px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      allItems: 'getItems',
      ralColors: 'getRalColors',
    }),
    editMode() {
      return this.id && this.$route.name === 'scheme-edit'
    },
    copyMode() {
      return this.id && this.$route.name === 'scheme-copy'
    },
  },
  mounted() {
    this.getColors()

    if (this.editMode) {
      this.initForm()
    }

    if (this.copyMode) {
      this.initCopy()
    }
  },
  methods: {
    async initForm() {
      this.loading = true
      await this.$store.dispatch('getSchemeDetail', Number(this.id)).then(async data => {
        const promiseResult = await Promise.allSettled(data.items.map(el => this.getItemsDetail(+el.id)))
        const items = []

        promiseResult.forEach((result, index) => {
          if (result.status === 'fulfilled' && result.value.type.id === 1) {
            items.push({
              data: result.value,
              coat: data.items[index].coat,
              dft: data.items[index].dry_film_thickness,
              loss: data.items[index].loss,
            })
          }
        })

        this.schemeData = {
          schemeName: data.name,
          notes: data.notes,
          color: data.color,
          selectedItem: items,
        }
        this.loading = false
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    async initCopy() {
      this.loading = true
      await this.$store.dispatch('getSchemeDetail', Number(this.id)).then(async data => {
        this.prevScheme = data
        const promiseResult = await Promise.allSettled(data.items.map(el => this.getItemsDetail(+el.id)))
        const items = []

        promiseResult.forEach((result, index) => {
          if (result.status === 'fulfilled' && result.value.type.id === 1) {
            items.push({
              data: result.value,
              coat: data.items[index].coat,
              dft: data.items[index].dry_film_thickness,
              loss: data.items[index].loss,
            })
          }
        })

        this.schemeData = {
          schemeName: '',
          notes: '',
          color: data.color,
          selectedItem: items,
        }
        this.loading = false
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    ...mapActions(['getItemsDetail', 'getColors']),
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['id_ral', 'hex_code', 'ind_name', 'eng_name'],
        shouldSort: true,
        findAllMatches: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item).slice(0, 10)
        : fuse.list
    },
    async handleSelectedItem(item) {
      this.showError = false
      this.loadingItem = true
      await this.getItemsDetail(Number(item.id)).then(result => {
        this.schemeData.selectedItem.push({
          data: result,
          coat: '',
          dft: '',
          loss: '',
        })
        this.loadingItem = false
      })

      const indexScheme = this.schemeData.selectedItem.length - 1

      if (this.schemeData.selectedItem[indexScheme].data.color && this.schemeData.selectedItem[indexScheme].data.color.eng_name) {
        this.schemeData.color = this.schemeData.selectedItem[indexScheme].data.color
      }
    },
    deleteItem(item) {
      this.$swal({
        title: 'Hapus Item?',
        text: 'Konfirmasi jika anda ingin menghapus item',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.schemeData.selectedItem.splice(item.originalIndex, 1)
        }
      })
    },
    submitForm() {
      this.$refs.validateScheme.validate().then(success => {
        if (success) {
          if (!this.schemeData.selectedItem.length) {
            this.showError = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Harap pilih minimal satu item!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          } else {
            this.loadingAdd = true
            const items = []

            this.schemeData.selectedItem.forEach(el => {
              items.push({
                item_id: el.data.id,
                coat: Number(el.coat),
                dry_film_thickness: Number(el.dft),
                loss: Number(el.loss),
              })
            })

            const payload = {
              name: this.schemeData.schemeName,
              notes: this.schemeData.notes,
              items,
              color_id_ral: this.schemeData.color ? this.schemeData.color.id_ral : null,
            }

            this.$store.dispatch('addScheme', payload).then(response => {
              if (response.status.toLowerCase() === 'success') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berhasil disubmit',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.loadingAdd = false
                this.$store.dispatch('getScheme')
                this.$router.replace({ name: 'scheme-detail', params: { id: response.id.toString() } })
              }
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Terjadi kesalahan!',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              this.loadingAdd = false
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Harap lengkapi data terlebih dahulu!',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    updateScheme() {
      this.$refs.validateScheme.validate().then(success => {
        if (success) {
          if (!this.schemeData.selectedItem.length) {
            this.showError = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Harap pilih minimal satu item!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          } else {
            this.loadingAdd = true
            const items = []

            this.schemeData.selectedItem.forEach(el => {
              items.push({
                item_id: el.data.id,
                coat: Number(el.coat),
                dry_film_thickness: Number(el.dft),
                loss: Number(el.loss),
              })
            })

            const payload = {
              id: +this.id,
              name: this.schemeData.schemeName,
              notes: this.schemeData.notes,
              items,
              color_id_ral: this.schemeData.color.id_ral,
            }

            this.$store.dispatch('updateScheme', payload).then(response => {
              if (response.status.toLowerCase() === 'success') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berhasil diupdate',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.loadingAdd = false
                this.$router.replace({ name: 'scheme-detail', params: { id: this.id.toString() } })
              }
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Terjadi kesalahan!',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              this.loadingAdd = false
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Harap lengkapi data terlebih dahulu!',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    resetForm() {
      this.$swal({
        title: 'Reset form?',
        text: 'Progres isian scheme anda akan hilang.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          if (this.editMode) {
            this.initForm()
          } else if (this.copyMode) {
            this.initCopy()
          } else {
            this.schemeData = {
              schemeName: '',
              notes: '',
              color: '',
              selectedItem: [],
            }
          }

          this.$refs.validateScheme.reset()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style scoped>
.customswatch{
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>
